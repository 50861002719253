/* Set box-sizing: border-box on all elements* */
html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	margin: 0;
	padding: 0;
}

/* Necessary to keep HUD animations from creating scrollbars */
body {
	overflow: hidden;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 14px;
	color: #C1C9D2;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #29323c;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #29323c;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #29323c;
}

/* Scrollbars */
/* adapted from https://www.digitalocean.com/community/tutorials/css-scrollbars */
/** {*/
/*	scrollbar-width: thin;*/
/*	scrollbar-color: #3e4354 #29323C;*/
/*}*/

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	background: #29323C;
}

*::-webkit-scrollbar-thumb {
	background-color: #3e4354;
	border-radius: 8px;
	width: 10px;
	border: 1px solid #29323C;
}

input[type=range] {
  height: 25px;
	background-color: transparent;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #60b3a1;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #60b3a1;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #3e4354;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #60b3a1;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #60b3a1;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #60b3a1;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #3e4354;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #60b3a1;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #60b3a1;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #60b3a1;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #3e4354;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #60b3a1;
}
input[type=range]:focus::-ms-fill-upper {
  background: #60b3a1;
}


/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.bar-chart-axis, .scatter-axis, [class*="histo-y-axis"], [class*="histo-x-axis"], [class*="event-line-y-axis"], [class*="event-line-x-axis"] {
  color: #6a7485;
}

