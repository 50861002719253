/* Turns off/hides some base styling on Mapbox Popup component*/
.mapboxgl-popup-content {
	padding: 8px 0 0;
	background-color: transparent;
}

.mapboxgl-popup-close-button {
	display: none;
}

.mapboxgl-popup-tip {
	display: none;
}

.mapboxgl-ctrl, .mapboxgl-ctrl-group {
	margin-right: 7px !important;
	background: none;
}

.mapboxgl-ctrl-attrib-inner, .mapboxgl-ctrl-logo {
	display: none !important;
}

.mapboxgl-ctrl-group {
	position: fixed;
	top: 40vh;
	right: 2px;
	height: 100%;
}

.mapboxgl-ctrl-group button {
	border-radius: 4px;
	width: 32px;
	height: 32px;
	box-shadow: none;
}

.mapboxgl-ctrl-group button+button {
	border-top: none;
	margin-top: 2px;
}

.mapboxgl-ctrl-icon {
	background-color: #3e4354;
}

.mapboxgl-ctrl-group:not(:empty) {
	box-shadow: none;
}
